import React, { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { MenuSquare, MapPin,Send,LayoutGrid,Compass  } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import './FilterByCountry.css'
import COUNTRY_COORDINATES from '../../COUNTRY_COORDINATES';

const QuickAccessCountry = ({ countryCode, countryInfo, onSelect, isSelected, isDelhiSelected }) => {
  const buttonClass = countryCode === 'delhi' || countryCode === 'in'
    ? `quick-access-country ${(isDelhiSelected || isSelected) ? 'active' : ''}`
    : `quick-access-country ${isSelected ? 'active' : ''}`;

  return (
    <button
      onClick={() => onSelect(countryCode)}
      className={buttonClass}
      data-region={countryCode}
    >
      {countryInfo.name}
    </button>
  );
};

const Modal = ({ isOpen, onClose, children, isMobile }) => {
  if (!isOpen) return null;

  return (
    <div className={`filter-modal-overlay ${isMobile ? 'mobile' : ''}`}>
      <div className={`filter-modal ${isMobile ? 'mobile' : ''}`}>
        <button className="filter-modal-close" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

const CountryModal = ({ onCountrySelect, availableCountries, selectedCountry, mapRef }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDelhiSelected, setIsDelhiSelected] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCountrySelect = (countryCode) => {
    if (countryCode === 'delhi') {
      setIsDelhiSelected(true);
      onCountrySelect('in', 'delhi');
      setTimeout(() => {
        if (mapRef.current) {
          const map = mapRef.current;
          map.flyTo([28.7041, 77.1025], 10, {
            duration: 1.5
          });
        }
      }, 100);
    } else {
      setIsDelhiSelected(false);
      onCountrySelect(countryCode, null);
      if (mapRef.current && COUNTRY_COORDINATES[countryCode]) {
        const map = mapRef.current;
        const coords = COUNTRY_COORDINATES[countryCode].coordinates;
        const zoom = COUNTRY_COORDINATES[countryCode].zoom;
        map.flyTo(coords, zoom, {
          duration: 1.5
        });
      }
    }
    setShowModal(false);
  };

  const countriesWithDelhiElections = {
    ...availableCountries,
    'delhi': {
      name: 'Delhi Elections',
      flag: 'delhi.png',
      description: 'Special focus on Delhi local elections'
    }
  };

  return (
    <div className='country-modal-container-outer'>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} isMobile={isMobile}>
        <div className="filter-modal-content">
          <h2>Select a Region</h2>
          <div className={`country-container ${isMobile ? 'mobile' : ''}`}>
            {Object.entries(countriesWithDelhiElections).map(([code, countryInfo]) => (
              <div key={code} className='country-div'>
                <div className='country-item'>
                  <img 
                    src={`flags/${countryInfo.flag || code + '.png'}`} 
                    alt={`${countryInfo.name} flag`} 
                    className='map-img'
                  />
                  <button
                    onClick={() => handleCountrySelect(code)}
                    className={`filter-country-button ${
                      (selectedCountry.selectedCountry === code || 
                       (code === 'delhi' && isDelhiSelected)) ? 'active' : 'inactive'}`}
                  >
                    {countryInfo.name}
                    {code === 'delhi' && <span className="election-badge"></span>}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      
      <div className='quick-access-region-button'>
        <div className="quick-access-countries">
          <QuickAccessCountry
              countryCode="delhi"
              countryInfo={{ name: 'Delhi Elections' }}
              onSelect={handleCountrySelect}
              isSelected={selectedCountry.selectedCountry === 'delhi'}
              isDelhiSelected={isDelhiSelected}
            />
            <QuickAccessCountry
              countryCode="in"
              countryInfo={{ name: 'India' }}
              onSelect={handleCountrySelect}
              isSelected={selectedCountry.selectedCountry === 'in'}
              isDelhiSelected={false}
            />
        </div>
        <div className='region-button-container' onClick={() => setShowModal(true)}>
          <Send size={30} strokeWidth='1' className="mr-2" />
          <button className='region-button'>Select Region</button>
        </div>
      </div>
    </div>
  );
};


const FilterByCountry = ({ onCategoryClick, onViewToggle, mapRef }) => {
  const { state, dispatch } = useAppContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showMobileCountryModal, setShowMobileCountryModal] = useState(false);
  const [activeView, setActiveView] = useState('map');

  const handleCountrySelect = (country, specialRegion = null) => {
    dispatch({ 
      type: 'SET_SELECTED_COUNTRY', 
      payload: country
    });
  };

  const availableCountries = useMemo(() => {
    const countriesToUse = state.availableCountries || [];
    return countriesToUse.reduce((acc, countryCode) => {
      const normalizedCode = countryCode.toLowerCase();
      const countryInfo = COUNTRY_COORDINATES[normalizedCode];
      if (countryInfo) {
        acc[normalizedCode] = {
          code: normalizedCode,
          name: countryInfo.name,
          ...countryInfo
        };
      }
      return acc;
    }, {});
  }, [state.availableCountries]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const currentView = window.location.hash.replace('#', '') || 'map';
      setActiveView(currentView);
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  const handleViewToggle = (view) => {
    setActiveView(view);
    onViewToggle(view);
  };

  const today = format(new Date(), 'MMMM d, yyyy');
  const MobileViewToggle = () => (
    <div className="mobile-view-toggle">
      <button 
        onClick={() => handleViewToggle('map')} 
        className={`view-toggle-button ${activeView === 'map' ? 'active' : ''}`}
      >
        <MapPin size={24} strokeWidth={1.5} />
      </button>

      <button 
        onClick={() => handleViewToggle('sidebar')} 
        className={`view-toggle-button ${activeView === 'sidebar' ? 'active' : ''}`}
      >
        <MenuSquare size={24} strokeWidth={1.5} />
      </button>

      <button 
        onClick={() => handleViewToggle('category')} 
        className={`view-toggle-button ${activeView === 'category' ? 'active' : ''}`}
      >
        <Compass size={24} strokeWidth={1.5} />
      </button>
      
      <button 
        onClick={() => handleViewToggle('region')} 
        className={`view-toggle-button ${activeView === 'region' ? 'active' : ''}`}
      >
        <LayoutGrid size={24} strokeWidth={1.5} />
      </button>
    </div>
  );

  const DesktopView = () => (
    <div className='desktop-view'>
      <div className="country-buttons">
        <img src='mmn4 1.png' alt="Logo" className='logo' />
        <div className="country-modal-container">
        <CountryModal
            availableCountries={availableCountries}
            selectedCountry={state}
            onCountrySelect={handleCountrySelect}
            mapRef={mapRef}
          />
        </div>
      </div>
      <div className="date-display">
        <p className='date-display-p'>{today}</p>
        <button className="category-desktop-filter-button" onClick={onCategoryClick}>
          {'Select Category'}
        </button>
      </div>
    </div>
  );

  const MobileView = () => (
    <div className="nav-mobile-view">
      <img src='mmn4 1.png' alt="Logo" className='logo' />
      <MobileViewToggle />
    </div>
  );


  return (
    <div className='filter-container'>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  );
};

export default FilterByCountry;