import React from 'react';
import './RegionView.css';
import COUNTRY_COORDINATES from '../../COUNTRY_COORDINATES';

const RegionView = ({ selectedCountry, onCountrySelect, onClose, availableCountries }) => {
  const CountryCard = ({ code, name, onSelect, isSelected }) => (
    <div
      className={`country-card ${isSelected ? 'active' : ''}`}
      onClick={() => onSelect(code)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onSelect(code);
        }
      }}
    >
      <div className="map-preview">
        <img src={`flags/${code}.png`} className="map-preview-image" alt={`Flag of ${name}`} />
        <div className={`country-name-label ${isSelected ? 'active' : ''}`}>
          {name}
        </div>
      </div>
    </div>
  );

  const handleDelhiSelect = () => {
    onCountrySelect('in', 'delhi'); 
    onClose();
  };

  return (
    <div className="region-mobile-view-content">
      <div className="region-mobile-view-header">
        <h2>Select Region</h2>
      </div>
      <div className="region-list">
        <CountryCard
          code="delhi"
          name="Delhi Elections"
          onSelect={handleDelhiSelect}
          isSelected={selectedCountry === 'delhi'}
        />
        {Object.entries(COUNTRY_COORDINATES).filter(([code]) => availableCountries.includes(code)).map(([code, countryInfo]) => (
          <CountryCard
            key={code}
            code={code}
            name={countryInfo.name}
            onSelect={(code) => {
              onCountrySelect(code);
              onClose();
            }}
            isSelected={selectedCountry === code}
          />
        ))}
      </div>
    </div>
  );
};

export default RegionView;
