import React, { lazy, Suspense, useEffect, useState, useCallback, useMemo,useRef } from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON, useMap, useMapEvents,Polygon,Circle,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import './App.css';
import FilterByCountry from './components/FilterByCountry/FilterByCountry';
import CategoryFilterModal from './components/CategoryFilterModal/CategoryFilterModal.js';
import { useAppContext } from './AppContext';
import { Geolocation } from '@capacitor/geolocation';
import { App } from '@capacitor/app';
import { X } from 'lucide-react';
import ArticlesDebugger from './ArticlesDebugger';
import MapGuide from './MapGuide';
import CategoryView from './components/CategoryView/CategoryView.js';
import RegionView from './components/RegionView/RegionView.js';
import COUNTRY_COORDINATES from './COUNTRY_COORDINATES.js';
import ErrorContainer from './components/ErrorContainer.js';

const API_BASE_URL = 'https://api.mapmynews.com/test-app.php';
const FETCH_COUNTRIES_URL = 'https://api.mapmynews.com/fetch_selected_countries.php';

const SideBar = lazy(() => import('./components/Sidebar/SideBar'));
const Dialog = lazy(() => import('./components/Dialog/Dialog'));

const subcategoryIcons = {
  'accident': '/PNG/PNG/accident.png',
  'accident/death': '/PNG/PNG/accident_death.png',
  'air defence': '/PNG/PNG/air defence.png',
  'airforce': '/PNG/PNG/air force.png',
  'business': '/PNG/PNG/business.png',
  'elections': '/PNG/PNG/elections.png',
  'emergency': '/PNG/PNG/emergency.png',
  'entertainment': '/PNG/PNG/entertainment.png',
  'explosion': '/PNG/PNG/explosion.png',
  'finance': '/PNG/PNG/finance.png',
  'financial institution': '/PNG/PNG/financial institution.png',
  'flight': '/PNG/PNG/flight.png',
  'general': '/PNG/PNG/General_ Announcement .png',
  'health': '/PNG/PNG/health.png',
  'legal': '/PNG/PNG/legal.png',
  'military': '/PNG/PNG/military.png',
  'missile': '/PNG/PNG/missile.png',
  'navy': '/PNG/PNG/navy.png',
  'navy ship': '/PNG/PNG/navyship.png',
  'science': '/PNG/PNG/science.png',
  'sports': '/PNG/PNG/sports.png',
  'technology': '/PNG/PNG/technology.png',
  'terror': '/PNG/PNG/terror.png',
  'vessel': '/PNG/PNG/vessel.png',
  'warzone': '/PNG/PNG/warzone.png',
};


const preloadMapTiles = (centerLat, centerLng, zoom) => {
  const tileUrl = (x, y, z) => `https://c.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  const numTiles = Math.pow(2, zoom);
  const x = Math.floor((centerLng + 180) / 360 * numTiles);
  const y = Math.floor((1 - Math.log(Math.tan(centerLat * Math.PI / 180) + 1 / Math.cos(centerLat * Math.PI / 180)) / Math.PI) / 2 * numTiles);

  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = tileUrl(x + i, y + j, zoom);
      document.head.appendChild(link);
    }
  }
};

const MapEvents = () => {
  const map = useMap();
  const { state } = useAppContext();
  
  useEffect(() => {
    if (!map || !state.selectedCountry) return;

    const countryCoords = COUNTRY_COORDINATES[state.selectedCountry];
    if (!countryCoords) return;

    const { coordinates, zoom } = countryCoords;
    
    map.flyTo(coordinates, zoom, {
      duration: 1.5,
      easeLinearity: 0.25
    });
    
  }, [state.selectedCountry, map]);

  return null;
};

const getLocationErrorMessage = (error) => {
  const errorMessages = {
    1: 'Location permission denied. Please enable location services.',
    2: 'Location information is unavailable.',
    3: 'The request to get user location timed out.',
  };
  return errorMessages[error.code] || 'Failed to get user location.';
};

L.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const getMarkerIcon = (subcategory) => {
  const iconUrl = subcategoryIcons[subcategory.toLowerCase()] || '/PNG/PNG/default.png';
  return L.icon({
    iconUrl: iconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });
};

const SetViewOnUser = React.memo(({ coords, shouldSetView }) => {
  const map = useMap();
  useEffect(() => {
    if (coords && shouldSetView) {
      map.setView(coords, 10, {
        animate: true,
        duration: 1
      });
    }
  }, [coords, map, shouldSetView]);
  return null;
});

const MapContent = React.memo(() => {
  const { state, dispatch } = useAppContext();
  const map = useMap();

  const handleMarkerClick = useCallback((article) => {
    dispatch({ type: 'SET_SELECTED_ARTICLE', payload: article });
    dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
  }, [dispatch]);

  const handleZoneClick = useCallback((zone) => {
    const zoneArticles = state.articles.filter(article => article.zone === zone);
    const otherArticles = state.articles.filter(article => article.zone !== zone);
    dispatch({ type: 'SET_GROUPED_ARTICLES', payload: [...zoneArticles, ...otherArticles] });
  }, [state.articles, handleMarkerClick, dispatch]);

  const renderZoneGeometry = useCallback((zone, data, color) => {
    if (data.type === 'Point') {
      return (
        <Circle
          center={[data.coordinates[1], data.coordinates[0]]}
          radius={20000}
          pathOptions={{ color: color, fillColor: color, fillOpacity: 0.2 }}
          eventHandlers={{ click: () => handleZoneClick(zone) }}
        >
        </Circle>
      );
    } else if (data.type === 'Polygon') {
      return (
        <Polygon
          positions={data.coordinates[0].map(coord => [coord[1], coord[0]])}
          pathOptions={{ color: color }}
          eventHandlers={{ click: () => handleZoneClick(zone) }}
        >
          <Popup>{zone}</Popup>
        </Polygon>
      );
    } else {
      console.error('Unsupported GeoJSON type:', data.type);
      return null;
    }
  }, [handleZoneClick]);

  const groupMarkersByLocation = useCallback((articles) => {
    const groupedMarkers = {};
    articles.forEach((article) => {
      if (article.latitude && article.longitude) {
        const key =`${article.latitude},${article.longitude}`;
        if (!groupedMarkers[key]) {
          groupedMarkers[key] = [];
        }
        groupedMarkers[key].push(article);
      }
    });
    return groupedMarkers;
  }, []);

  const renderGroupedMarkers = useCallback((groupedMarkers) => {
    return Object.entries(groupedMarkers).map(([key, articles]) => {
      const [lat, lng] = key.split(',').map(Number);
      const offsetStep = 10;

      return articles.map((article, index) => {
        const offset = index * offsetStep;
        const icon = getMarkerIcon(article.subcategory);
        const adjustedLatLng = map.containerPointToLatLng(
          map.latLngToContainerPoint([lat, lng]).add([offset, 0])
        );

        return (
          <Marker
            key={`${article.id}-${index}`}
            position={adjustedLatLng}
            icon={icon}
            eventHandlers={{
              click: () => handleMarkerClick(article)
            }}
          />
        );
      });
    });
  }, [map, handleMarkerClick]);

  const filteredZones = useMemo(() => {
    if (!state.articles || !state.geoJSONData) return {};
    
    const countryZones = state.articles
      .filter(article => article.country === state.selectedCountry)
      .map(article => article.zone)
      .filter(Boolean);
    
    const filteredGeoJSON = {};
    countryZones.forEach(zone => {
      if (state.geoJSONData[zone]) {
        filteredGeoJSON[zone] = state.geoJSONData[zone];
      }
    });
    
    return filteredGeoJSON;
  }, [state.articles, state.geoJSONData, state.selectedCountry]);

  const groupedMarkers = useMemo(() => groupMarkersByLocation(state.filteredArticles), [state.filteredArticles, groupMarkersByLocation]);


  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      
      {Object.entries(filteredZones).map(([zone, data]) => (
        <React.Fragment key={zone}>
          {renderZoneGeometry(zone, data, state.zoneColors[zone] || 'blue')}
        </React.Fragment>
      ))}
      
      {renderGroupedMarkers(groupedMarkers)}
      <MapGuide/>
    </>
  );
});

const MapViewController = ({ coordinates, zoom }) => {
  const map = useMap();
  
  useEffect(() => {
    if (coordinates && zoom && map) {
      map.setView(coordinates, zoom, {
        animate: true,
        duration: 1
      });
    }
  }, [coordinates, zoom, map]);

  return null;
};

const MapComponent = () => {
  const { state, dispatch } = useAppContext();
  const [map, setMap] = useState(null);
  const mapRef=useRef(null);
  const [activeView, setActiveView] = useState('map');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [shouldFollowUserLocation, setShouldFollowUserLocation] = useState(true);
  const [hasSelectedCountry, setHasSelectedCountry] = useState(false);
  const [shouldSetViewToUser, setShouldSetViewToUser] = useState(true);
  const [localState, setLocalState] = useState({
    isDialogOpen: false,
    loading: true,
    error: null,
    locationError: null, 
    selectedZoneArticles: [],
    showLocationError: false,
    isLocationOn: false,
    hasLocationPermission: false,
    locationErrorType: null,
    showLocationBanner: false ,
    isLocationFetched: false,
    currentDate: new Date(),
    isFirstPage: true, 
    hasOlderNews: true,
    availableCountries: []
  });


  const mapViewSettings = useMemo(() => {
    if (state.mapView) {
      return {
        coordinates: state.mapView.coordinates,
        zoom: state.mapView.zoom
      };
    } else if (state.selectedCountry && COUNTRY_COORDINATES[state.selectedCountry]) {
      const country = COUNTRY_COORDINATES[state.selectedCountry];
      return {
        coordinates: country.coordinates,
        zoom: country.zoom
      };
    } else {
      return {
        coordinates: COUNTRY_COORDINATES['in'].coordinates,
        zoom: COUNTRY_COORDINATES['in'].zoom
      };
    }
  }, [state.mapView, state.selectedCountry]);

  const api = useMemo(() => axios.create({
    baseURL: API_BASE_URL,
    headers: { 'Content-Type': 'application/json' }
  }), []);

  const handleViewToggle = (view) => {
    setActiveView(view);
    setShouldSetViewToUser(false);
    window.location.hash = view;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchAvailableCountries = useCallback(async () => {
    try {
      const response = await axios.get(FETCH_COUNTRIES_URL);
      
      if (response.data && response.data.selectedCountries) {

        dispatch({
          type: 'SET_AVAILABLE_COUNTRIES',
          payload: response.data.selectedCountries
        });

        const defaultCountry = response.data.selectedCountries[0];

        dispatch({ 
          type: 'SET_SELECTED_COUNTRY', 
          payload: defaultCountry 
        });

        // console.log(response.data.selectedCountries);
        
        return response.data.selectedCountries;
      }
    } catch (error) {
      console.error('Error fetching available countries:', error);
      setLocalState(prev => ({
        ...prev,
        error: 'Failed to fetch available countries. Please try again later.'
      }));
      return {};
    }
  }, [dispatch]);



  const formatDateForAPI = useCallback((date) => {
    return date.toISOString().split('T')[0];
  }, []);

  const getInitialDate = useCallback(() => {
    const today = new Date();
    return today;
  }, []);

  const mapCenter = useMemo(() => [20.593684, 78.96288], []); 
  const mapZoom = useMemo(() => 5, []);

  const availableCountries =state.availableCountries;  

  const requestLocationPermission = useCallback(async () => {
    try {
      const permissionStatus = await Geolocation.requestPermissions();
      const isGranted = permissionStatus.location === 'granted';
      setLocalState(prev => ({ ...prev, hasLocationPermission: isGranted }));
      if (isGranted) {
        const isLocationEnabled = await Geolocation.isLocationEnabled();
        setLocalState(prev => ({ 
          ...prev, 
          isLocationOn: isLocationEnabled,
          showLocationBanner: !isLocationEnabled,
          showLocationError: !isLocationEnabled
        }));
      }
      return isGranted;
    } catch (error) {
      console.error('Error requesting location permission:', error);
      return false;
    }
  }, []);


  const getUserLocation = useCallback(async () => {
    try {
      setLocalState(prev => ({ ...prev, isLocationFetched: true }));
      const position = await Geolocation.getCurrentPosition();
      if (position && !hasSelectedCountry) {
        dispatch({ 
          type: 'SET_USER_LOCATION', 
          payload: [position.coords.latitude, position.coords.longitude] 
        });
      }
      setLocalState(prev => ({ ...prev, isLocationFetched: false }));
    } catch (error) {
      console.error('Error getting user location:', error);
      dispatch({ type: 'SET_LOCATION_ERROR', payload: getLocationErrorMessage(error) });
      setLocalState(prev => ({ ...prev, isLocationFetched: true }));
    }
  }, [dispatch, hasSelectedCountry]);


  const filteredArticles = useMemo(() => {
    if (!state.articles || !Array.isArray(state.articles)) {
      return [];
    }
    return state.articles.filter(article => {
      const categoryMatch = state.activeCategory === 'all' || article.category === state.activeCategory;
      const countryMatch = !state.selectedCountry || article.country === state.selectedCountry;
      const selectedCategoryMatch = state.selectedCategories.length === 0 || state.selectedCategories.includes(article.category);
      const selectedSubCategoryMatch = state.selectedSubCategories.length === 0 || state.selectedSubCategories.includes(article.category);
      return categoryMatch && countryMatch && selectedCategoryMatch && selectedSubCategoryMatch;
    });
  }, [state.articles, state.selectedCountry, state.activeCategory, state.selectedCategories, state.selectedSubCategories]);

  const handleCloseErrorBanner = useCallback(() => {    
    setLocalState(prev => ({ ...prev, showLocationBanner: false }));
  }, []);

  const checkLocationStatus = useCallback(async () => {
    try {
      const permissionStatus = await Geolocation.checkPermissions();
      const isLocationEnabled = await Geolocation.isLocationEnabled();
      
      setLocalState(prev => ({
        ...prev,
        hasLocationPermission: permissionStatus.location === 'granted',
        isLocationOn: isLocationEnabled,
        showLocationBanner: false,
        locationErrorType: permissionStatus.location !== 'granted' ? 'permission' : 'locationOff'
      }));
  
      return { 
        hasPermission: permissionStatus.location === 'granted', 
        isLocationOn: isLocationEnabled 
      };
    } catch (error) {
      console.error('Error checking location status:', error);
      return { hasPermission: false, isLocationOn: false };
    }
  }, []);

  const getGeoJSONFromZone = useCallback(async (zoneName) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: zoneName,
          format: 'json',
          polygon_geojson: 1,
        },
      });

      return response.data.length > 0 ? response.data[0].geojson : null;
    } catch (error) {
      console.error('Error fetching geojson:', error);
      return null;
    }
  }, []);

  const fetchAvailableDates = useCallback(async () => {
    try {
      const response = await api.get('https://api.mapmynews.com/test.php'); 
      
      if (response.data && Array.isArray(response.data)) {
        dispatch({ type: 'SET_AVAILABLE_DATES', payload: response.data });
      }
    } catch (error) {
      console.error('Error fetching available dates:', error);
      setLocalState(prev => ({
        ...prev,
        error: 'Failed to fetch available dates. Please try again later.'
      }));
    }
  }, [api, dispatch]);

  const fetchArticlesAndGeoJSON = useCallback(async (date, attempts = 0, maxAttempts = 7) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const formattedDate = formatDateForAPI(date);
      const response = await api.get('', {
        params: {
          date: formattedDate
        }
      });
  
      if (!response.data) {
        throw new Error('No data received from API');
      }
  
      let fetchedArticles;
      let actualDate = formattedDate;
  
      // Handle the new response format
      if (response.data.articles) {
        fetchedArticles = response.data.articles;
        actualDate = response.data.actualDate || formattedDate;
      } else {
        fetchedArticles = response.data;
      }
  
      if (!Array.isArray(fetchedArticles)) {
        console.error('Invalid data format:', fetchedArticles);
        throw new Error('Invalid data format received from API');
      }
  
      if (fetchedArticles.length === 0 && attempts < maxAttempts) {
        const previousDate = new Date(date);
        previousDate.setDate(previousDate.getDate() - 1);
  
        dispatch({
          type: 'SET_LOADING',
          payload: {
            status: true,
            message: `No news found for ${formattedDate}, checking ${formatDateForAPI(previousDate)}...`
          }
        });
  
        return fetchArticlesAndGeoJSON(previousDate, attempts + 1, maxAttempts);
      }
  
      if (fetchedArticles.length > 0) {
        const currentDateObj = new Date(actualDate);
        const dateIndex = state.availableDates.findIndex(date => 
          new Date(date).toDateString() === currentDateObj.toDateString()
        );
        
        if (dateIndex !== -1) {
          dispatch({ type: 'SET_CURRENT_DATE_INDEX', payload: dateIndex });
        }
        
        setLocalState(prev => ({
          ...prev,
          currentDate: actualDate,
          hasOlderNews: true
        }));
      } else {
        setLocalState(prev => ({
          ...prev,
          hasOlderNews: false
        }));
      }
  
      dispatch({ type: 'SET_ARTICLES', payload: fetchedArticles });
      dispatch({ type: 'SET_LOADING', payload: false });
  
      const processGeoJSON = async () => {
        const uniqueZones = new Set(fetchedArticles.map((article) => article.zone));
        const geoData = {};
        const colors = {};
  
        await Promise.all(Array.from(uniqueZones).map(async (zone) => {
          if (zone) {
            try {
              const geoJSON = await getGeoJSONFromZone(zone);
              if (geoJSON) {
                geoData[zone] = geoJSON;
                const articleWithColor = fetchedArticles.find(article => article.zone === zone && article.color);
                colors[zone] = articleWithColor ? articleWithColor.color : 'blue';
              }
            } catch (error) {
              console.warn(`Failed to fetch GeoJSON for zone ${zone}:`, error);
            }
          }
        }));
  
        dispatch({ type: 'SET_GEO_JSON_DATA', payload: geoData });
        dispatch({ type: 'SET_ZONE_COLORS', payload: colors });
      };
  
      processGeoJSON();
    } catch (error) {
      console.error('Error fetching articles:', error);
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [api, dispatch, formatDateForAPI]);


  useEffect(() => {
    const initializeMap = async () => {
      const coordinates = await fetchAvailableCountries();
      
      await fetchAvailableDates();
      preloadMapTiles(mapCenter[0], mapCenter[1], mapZoom);
      
      // Only get initial location if no country selected
      if (!hasSelectedCountry && !state.selectedCountry) {
        const { hasPermission, isLocationOn } = await checkLocationStatus();
        if (!hasPermission) {
          const granted = await requestLocationPermission();
          if (granted) {
            await getUserLocation();
          } else {
            setLocalState(prev => ({ ...prev, showLocationBanner: true }));
          }
        } else if (isLocationOn) {
          await getUserLocation();
        } else {
          setLocalState(prev => ({ ...prev, showLocationBanner: true }));
        }
      }
      
      const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    
    setLocalState(prev => ({ ...prev, currentDate: today }));
    await fetchArticlesForMultipleDays([today, yesterday]);
    };
    
    initializeMap();
  }, []);

  const fetchArticlesForMultipleDays = async (dates) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      
      const responses = await Promise.all(
        dates.map(date => 
          api.get('', {
            params: {
              date: formatDateForAPI(date)
            }
          })
        )
      );
  
      let allArticles = [];
      let actualDates = [];
  
      responses.forEach((response, index) => {
        if (!response.data) return;
  
        let articles;
        let actualDate = formatDateForAPI(dates[index]);
  
        if (response.data.articles) {
          articles = response.data.articles;
          actualDate = response.data.actualDate || actualDate;
        } else {
          articles = response.data;
        }
  
        if (Array.isArray(articles)) {
          // Add date info to each article
          articles = articles.map(article => ({
            ...article,
            displayDate: actualDate
          }));
          allArticles = [...allArticles, ...articles];
          actualDates.push(actualDate);
        }
      });
  
      dispatch({ type: 'SET_ARTICLES', payload: allArticles });
      dispatch({ type: 'SET_LOADING', payload: false });
  
      return { articles: allArticles, dates: actualDates };
    } catch (error) {
      console.error('Error fetching articles:', error);
      dispatch({ type: 'SET_LOADING', payload: false });
      throw error;
    }
  };

  const handleDateChange = useCallback(async (direction) => {
    const { availableDates, currentDateIndex } = state;
    let newIndex = currentDateIndex;
    
    if (direction === 'previous' && currentDateIndex < availableDates.length - 2) {
      newIndex += 2;
    } else if (direction === 'next' && currentDateIndex > 1) {
      newIndex -= 2;
    } else {
      console.warn("No more dates in the given direction.");
      return;
    }
    
    dispatch({ type: 'SET_CURRENT_DATE_INDEX', payload: newIndex });
    
    // Fetch two days of data
    const date1 = new Date(availableDates[newIndex]);
    const date2 = new Date(availableDates[newIndex + 1]);
    
    await fetchArticlesForMultipleDays([date1, date2]);
    
    if (isMobile) {
      setActiveView('map');
    }
  }, [state.availableDates, state.currentDateIndex, dispatch, fetchArticlesForMultipleDays, isMobile, setActiveView]);

  useEffect(() => {
    if (state.mapView && map) {
      map.setView(
        state.mapView.coordinates,
        state.mapView.zoom
      );
    }
  }, [state.mapView, map]);


  useEffect(() => {
    const checkLocationStatus = async () => {
      try {
        const isLocationEnabled = await Geolocation.isLocationEnabled();
        setLocalState(prev => ({
          ...prev,
          showLocationBanner: false
        }));
      } catch (error) {
        console.error('Error checking location status:', error);
        setLocalState(prev => ({
          ...prev,
          showLocationBanner: true
        }));
      }
    };

    checkLocationStatus();
  }, []);

  useEffect(() => {
    if (!hasSelectedCountry && !state.selectedCountry && !state.userLocation) {
      getUserLocation();
    }
  }, [getUserLocation, hasSelectedCountry, state.selectedCountry, state.userLocation]);


  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      if (state.loading) {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }, 1000000);
  
    return () => clearTimeout(loadingTimeout);
  }, [state.loading, dispatch]);

  const handleMarkerClick = useCallback((article) => {
    dispatch({ type: 'SET_SELECTED_ARTICLE', payload: article });
    dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
  }, [dispatch]);

  const handleCountryFilter = useCallback((country, specialRegion) => {
    
    if (specialRegion === 'delhi') {
      dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
      setTimeout(() => {
        if (mapRef.current) {
          const map = mapRef.current;
          map.flyTo([28.7041, 77.1025], 10, {
            duration: 1.5
          });
        }
      }, 100);
    } else if (COUNTRY_COORDINATES[country]) {
      setHasSelectedCountry(true);
      setShouldFollowUserLocation(false);
      dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
    }
    
    if (isMobile) {
      setActiveView('map');
    }
  }, [dispatch]);
  

  const resetCountryFilter = useCallback(() => {
    setHasSelectedCountry(false);
    setShouldFollowUserLocation(true);
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: null });
    getUserLocation();
  }, [dispatch, getUserLocation]);

  const handleCategoryModal = useCallback(() => {
    setIsCategoryModalOpen(prev => !prev);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsCategoryModalOpen(false);
  }, []);

  const handleCategorySubmit = useCallback(({ categories, subCategories }) => {
    dispatch({ type: 'SET_SELECTED_CATEGORIES', payload: categories });
    dispatch({ type: 'SET_SELECTED_SUB_CATEGORIES', payload: subCategories });
  }, [dispatch]);

  if (state.loading) {
    return <div className="loading-container">Loading news data...</div>;
  }

  if (localState.error) {
    return <ErrorContainer message={localState.error} />;
  }

  if (!state.articles || state.articles.length === 0) {
    return <div className="loading-container">No news data available.</div>;
  }

<ArticlesDebugger state={state} filteredArticles={filteredArticles} />

return (
  <div className="app-container">
    {localState.showLocationBanner && localState.isLocationFetched && (
      <div className="location-banner" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: '#FEF3C7',
        color: '#721c24',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1004
      }}>
        <span>Unable to fetch user location. Please turn on the device location.</span>
        <button 
          onClick={handleCloseErrorBanner}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '20px'
          }}
        >
          <X size={24} />
        </button>
      </div>
    )}
    <FilterByCountry 
      onCountrySelect={handleCountryFilter}
      selectedCountry={state.selectedCountry}
      onReset={resetCountryFilter}
      availableCountries={availableCountries}
      onCategoryClick={handleCategoryModal}
      onViewToggle={handleViewToggle}
      activeView={activeView}
      mapRef={mapRef}
    />
    <div className={`map-sidebar ${isMobile ? 'mobile-view' : ''}`} data-view={activeView}>
    {isMobile ? (
      <>
        {activeView === 'map' && (
          <div className={`map-container-mobile`}>
            <MapContainer
              ref={mapRef}
              center={COUNTRY_COORDINATES['in'].coordinates}
              zoom={COUNTRY_COORDINATES['in'].zoom}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <MapEvents />
              <MapContent />
              {state.userLocation && 
                <SetViewOnUser 
                  coords={state.userLocation} 
                  shouldSetView={shouldSetViewToUser}
                />
              }
            </MapContainer>
          </div>
        )}
        {activeView === 'sidebar' && (
          <div className="sidebar-mobile">
            <Suspense fallback={<div role="status" aria-live="polite">Loading sidebar...</div>}>
              <SideBar
                articles={state.groupedArticles || state.filteredArticles || []}
                selectedArticle={state.selectedArticle?.id}
                onArticleClick={handleMarkerClick}
                currentDate={state.availableDates[state.currentDateIndex]}
                onDateChange={handleDateChange}
                isFirstDate={state.currentDateIndex === 0}
                isLastDate={state.currentDateIndex >= state.availableDates.length - 2}
                currentDateString={(state.availableDates[state.currentDateIndex])}
                availableDates={state.availableDates}
              />
            </Suspense>
          </div>
        )}
        {activeView === 'category' && (
          <div className="category-view-mobile">
            <CategoryView 
              onSubmit={handleCategorySubmit}
              onClose={() => handleViewToggle('map')}
            />
          </div>
        )}
        {activeView === 'region' && (
          <div className="region-view-mobile">
            <RegionView
              selectedCountry={state.selectedCountry}
              onCountrySelect={handleCountryFilter}
              onClose={() => handleViewToggle('map')}
              availableCountries={availableCountries}
            />
          </div>
        )}
      </>
    ) : (
      <>
        <div className="map-container" role="application" aria-label="Interactive news map">
          <MapContainer
            center={COUNTRY_COORDINATES['in'].coordinates}
            zoom={COUNTRY_COORDINATES['in'].zoom}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MapEvents />
            <MapContent />
            {state.userLocation && 
              <SetViewOnUser 
                coords={state.userLocation} 
                shouldSetView={shouldSetViewToUser}
              />
            }
          </MapContainer>
        </div>
        <Suspense fallback={<div role="status" aria-live="polite">Loading sidebar...</div>}>
          <SideBar
            articles={state.groupedArticles || state.filteredArticles || []}
            selectedArticle={state.selectedArticle?.id}
            onArticleClick={handleMarkerClick}
            currentDate={state.availableDates[state.currentDateIndex]}
            onDateChange={handleDateChange}
            isFirstDate={state.currentDateIndex === 0}
            isLastDate={state.currentDateIndex === state.availableDates.length - 1}
            currentDateString={(state.availableDates[state.currentDateIndex])}
            availableDates={state.availableDates}
          />
        </Suspense>
      </>
    )}
     {isCategoryModalOpen && (
        <CategoryFilterModal onClose={handleCloseModal} />
      )}
      <Suspense fallback={<div role="status" aria-live="polite">Loading dialog...</div>}>
        <Dialog articles={state.articles}/>
      </Suspense>
    </div>
  </div>
);
};

export default React.memo(MapComponent);