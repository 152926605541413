const COUNTRY_COORDINATES  = {
  'ar': { name: 'Argentina', coordinates: [-38.416097, -63.616672], zoom: 4, url:'country/argentina' },
  'au': { name: 'Australia', coordinates: [-25.274398, 133.775136], zoom: 4, url:'country/australia' },
  'at': { name: 'Austria', coordinates: [47.516231, 14.550072], zoom: 7, url:'country/austria' },
  'be': { name: 'Belgium', coordinates: [50.503887, 4.469936], zoom: 7, url:'country/belgium' },
  'br': { name: 'Brazil', coordinates: [-14.235004, -51.925280], zoom: 4, url:'country/brazil' },
  'bg': { name: 'Bulgaria', coordinates: [42.733883, 25.485830], zoom: 7, url:'country/bulgaria' },
  'ca': { name: 'Canada', coordinates: [56.130366, -106.346771], zoom: 3, url:'country/canada' },
  'cn': { name: 'China', coordinates: [35.861660, 104.195397], zoom: 4, url:'country/china' },
  'co': { name: 'Colombia', coordinates: [4.570868, -74.297333], zoom: 6, url:'country/colombia' },
  'cz': { name: 'Czech Republic', coordinates: [49.817492, 15.472962], zoom: 7, url:'country/czech_republic' },
  'eg': { name: 'Egypt', coordinates: [26.820553, 30.802498], zoom: 6, url:'country/egypt' },
  'fr': { name: 'France', coordinates: [46.227638, 2.213749], zoom: 6, url:'country/france' },
  'de': { name: 'Germany', coordinates: [51.165691, 10.451526], zoom: 6, url:'country/germany' },
  'gr': { name: 'Greece', coordinates: [39.074208, 21.824312], zoom: 7, url:'country/greece' },
  'hk': { name: 'Hong Kong', coordinates: [22.396428, 114.109497], zoom: 10, url:'country/hong_kong' },
  'hu': { name: 'Hungary', coordinates: [47.162494, 19.503304], zoom: 7, url:'country/hungary' },
  'in': { name: 'India', coordinates: [20.593684, 78.96288], zoom: 5, url:'country/india' },
  'id': { name: 'Indonesia', coordinates: [-0.789275, 113.921327], zoom: 5, url:'country/indonesia' },
  'ir': { name: 'Ireland', coordinates: [53.412910, -8.243890], zoom: 7, url:'country/ireland' },
  'il': { name: 'Israel', coordinates: [31.046051, 34.851612], zoom: 7, url:'country/israel' },
  'it': { name: 'Italy', coordinates: [41.871940, 12.567380], zoom: 6, url:'country/italy' },
  'jp': { name: 'Japan', coordinates: [36.204824, 138.252924], zoom: 5, url:'country/japan' },
  'lv': { name: 'Latvia', coordinates: [56.879635, 24.603189], zoom: 7, url:'country/latvia' },
  'lt': { name: 'Lithuania', coordinates: [55.169438, 23.881275], zoom: 7, url:'country/lithuania' },
  'my': { name: 'Malaysia', coordinates: [4.210484, 101.975766], zoom: 6, url:'country/malaysia' },
  'mx': { name: 'Mexico', coordinates: [23.634501, -102.552784], zoom: 5, url:'country/mexico' },
  'ma': { name: 'Morocco', coordinates: [31.791702, -7.092620], zoom: 6, url:'country/morocco' },
  'nl': { name: 'Netherlands', coordinates: [52.132633, 5.291266], zoom: 7, url:'country/netherlands' },
  'nz': { name: 'New Zealand', coordinates: [-40.900557, 174.885971], zoom: 5, url:'country/new_zealand' },
  'ng': { name: 'Nigeria', coordinates: [9.081999, 8.675277], zoom: 6, url:'country/nigeria' },
  'no': { name: 'Norway', coordinates: [60.472024, 8.468946], zoom: 5, url:'country/norway' },
  'ph': { name: 'Philippines', coordinates: [12.879721, 121.774017], zoom: 6, url:'country/philippines' },
  'pl': { name: 'Poland', coordinates: [51.919438, 19.145136], zoom: 6, url:'country/poland' },
  'pt': { name: 'Portugal', coordinates: [39.399872, -8.224454], zoom: 7, url:'country/portugal' },
  'ro': { name: 'Romania', coordinates: [45.943161, 24.966760], zoom: 7, url:'country/romania' },
  'sa': { name: 'Saudi Arabia', coordinates: [23.885942, 45.079162], zoom: 5, url:'country/saudi_arabia' },
  'rs': { name: 'Serbia', coordinates: [44.016521, 21.005859], zoom: 7, url:'country/serbia' },
  'sg': { name: 'Singapore', coordinates: [1.352083, 103.819836], zoom: 11, url:'country/singapore' },
  'sk': { name: 'Slovakia', coordinates: [48.669026, 19.699024], zoom: 7, url:'country/slovakia' },
  'si': { name: 'Slovenia', coordinates: [46.151241, 14.995463], zoom: 8, url:'country/slovenia' },
  'za': { name: 'South Africa', coordinates: [-30.559482, 22.937506], zoom: 5, url:'country/south_africa' },
  'kr': { name: 'South Korea', coordinates: [35.907757, 127.766922], zoom: 7, url:'country/south_korea' },
  'se': { name: 'Sweden', coordinates: [60.128161, 18.643501], zoom: 5, url:'country/sweden' },
  'ch': { name: 'Switzerland', coordinates: [46.818188, 8.227512], zoom: 8, url:'country/switzerland' },
  'tw': { name: 'Taiwan', coordinates: [23.697810, 120.960515], zoom: 7, url:'country/taiwan' },
  'th': { name: 'Thailand', coordinates: [15.870032, 100.992541], zoom: 6, url:'country/thailand' },
  'tr': { name: 'Turkey', coordinates: [38.963745, 35.243322], zoom: 6, url:'country/turkey' },
  'ae': { name: 'UAE', coordinates: [23.424076, 53.847818], zoom: 7, url:'country/uae' },
  'ua': { name: 'Ukraine', coordinates: [48.379433, 31.165581], zoom: 6, url:'country/ukraine' },
  'gb': { name: 'United Kingdom', coordinates: [55.378051, -3.435973], zoom: 5, url:'country/united_kingdom' },
  'us': { name: 'United States', coordinates: [37.090240, -95.712891], zoom: 4, url:'country/united_states' },
  've': { name: 'Venezuela', coordinates: [6.423750, -66.589730], zoom: 6, url:'country/venezuela' },
  'ru': { name: 'Russia', coordinates: [61.524010, 105.318756], zoom: 3, url:'country/russia' },
  'ia': { name: 'Iran', coordinates: [32.427908, 53.688046], zoom: 5, url:'country/iran' },
  'sy': { name: 'Syria', coordinates: [34.802075, 38.996815], zoom: 6,url:'country/syria' },
  'pa': { name: 'Palestine', coordinates: [31.952162, 35.233154], zoom: 8, url:'country/palestine' }
};

export default COUNTRY_COORDINATES;