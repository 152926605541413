import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
      <div className="privacy-container">
        <div className="privacy-policy-card">
          <h1 className="main-title">Privacy Policy</h1>
          <p className="effective-date">Effective Date: 23/01/2024</p>
  
          <section className="policy-section">
            <h2 className="privacy-section-title">INTRODUCTION</h2>
            <p>Welcome to MapMyNews, an interactive news mapping platform "Mapped News Aggregator" operated by Abiomye Industries Private Limited, located at 56, Sector 2, IIE SIDCUL, Haridwar, India.</p>
            <p>This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our website and services.</p>
            <p>If you have any questions, please contact our Nodal Officer at <a href="mailto:nodal@mapmynews.com" className="contact-link">nodal@mapmynews.com</a>.</p>
          </section>
  
          <section className="policy-section">
            <h2 className="privacy-section-title">INFORMATION WE COLLECT</h2>
            <h3 className="subsection-title">Personal Information:</h3>
            <ul className="policy-list">
              <li>Name, email address, and contact details when you subscribe or contact us</li>
              <li>Any other information you voluntarily provide</li>
            </ul>
            <h3 className="subsection-title">Non-Personal Information:</h3>
            <ul className="policy-list">
              <li>IP address, browser type, operating system, and technical details</li>
              <li>Usage data, including pages viewed, time spent, and platform interaction</li>
            </ul>
          </section>
  
          <section className="policy-section">
            <h2 className="privacy-section-title">HOW WE USE YOUR INFORMATION</h2>
            <ul className="policy-list">
              <li>Provide and maintain our services</li>
              <li>Improve user experience and website functionality</li>
              <li>Send updates, notifications, or promotional materials (with consent)</li>
              <li>Monitor and analyze usage trends</li>
              <li>Ensure security and prevent fraudulent activities</li>
            </ul>
          </section>
  
          <section className="policy-section">
            <h2 className="privacy-section-title">SHARING OF INFORMATION</h2>
            <p>We do not sell or rent your personal information. We may share information:</p>
            <ul className="policy-list">
              <li>With trusted third-party service providers</li>
              <li>To comply with legal obligations</li>
              <li>In connection with business transfers, mergers, or acquisitions</li>
            </ul>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">THIRD-PARTY LINKS AND CONTENT</h2>
            <p>MapMyNews aggregates news from various sources. We are not responsible for external sites' privacy practices. Users should review linked websites' privacy policies.</p>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">COOKIED AND TRACKING TECHNOLOGIES</h2>
            <p>We use cookies to enhance user experience, analyze trends, and manage the website. You can control cookies through browser settings.</p>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">DATA SECURITY</h2>
            <p>We implement security measures to protect your information from unauthorized access. However, no internet transmission is completely secure.</p>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">YOUR RIGHTS</h2>
            <p>Depending on your location, you may have rights including:</p>
            <ul className="policy-list">
              <li>Access, update, or delete your data</li>
              <li>Object to or restrict data processing</li>
              <li>Data portability</li>
            </ul>
            <p>To exercise these rights, contact us at <a href="mailto:nodal@mapmynews.com" className="contact-link">nodal@mapmynews.com</a>.</p>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">RETENTION OF DATA</h2>
            <p>We retain your information only as necessary to provide services and comply with legal obligations.</p>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">CHANGES TO THIS PRIVACY POLICY</h2>
            <p>We may update this policy periodically. Changes will be posted here with an updated effective date.</p>
          </section>

          <section className="policy-section">
            <h2 className="privacy-section-title">CONTACT US</h2>
            <p>Abiomye Industries Private Limited</p>
            <p>56, Sector 2, IIE SIDCUL, Haridwar, India</p>
            <p>Email: <a href="mailto:nodal@mapmynews.com" className="contact-link">nodal@mapmynews.com</a></p>
          </section>
        </div>
      </div>
    );
};

export default PrivacyPolicy;