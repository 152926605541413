import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../AppContext';
import './CategoryView.css';

const CategoryView = ({ onSubmit, onClose }) => {
  const { state, dispatch } = useAppContext();
  const { news, conflict } = subCategories;
  const allSubCategories = [...news, ...conflict];

  const [selectedSubCategories, setSelectedSubCategories] = useState(
    state.isFilterApplied ? state.selectedSubCategories : allSubCategories
  );

  useEffect(() => {
    setSelectedSubCategories(
      state.isFilterApplied ? state.selectedSubCategories : allSubCategories
    );
  }, [state.selectedSubCategories, state.isFilterApplied]);

  const handleSubCategoryToggle = (subCategory) => {
    setSelectedSubCategories(prev => 
      prev.includes(subCategory)
        ? prev.filter(item => item !== subCategory)
        : [...prev, subCategory]
    );
  };

  const handleSelectAllCategory = (categories) => {
    const allCategoriesSelected = categories.every(cat => selectedSubCategories.includes(cat));
    
    if (allCategoriesSelected) {
      // Deselect all categories in this section
      setSelectedSubCategories(prev => prev.filter(item => !categories.includes(item)));
    } else {
      // Select all categories in this section
      setSelectedSubCategories(prev => {
        const filtered = prev.filter(item => !categories.includes(item));
        return [...filtered, ...categories];
      });
    }
  };

  const getIconForSubCategory = (subCategory) => {
    const categoryData = categoryIcons.find(cat => cat.id.toLowerCase() === subCategory.toLowerCase());
    return categoryData ? categoryData.icon : null;
  };

  const handleSubmit = () => {
    dispatch({ type: 'SET_SELECTED_SUB_CATEGORIES', payload: selectedSubCategories });
    onClose();
  };

  const renderCategorySection = (title, categories) => {
    const allSelected = categories.every(cat => selectedSubCategories.includes(cat));
    
    return (
      <div className="category-section">
        <div className="section-header">
          <h3 className="subcategories-title">{title}</h3>
          <button 
            onClick={() => handleSelectAllCategory(categories)}
            className={`select-all-button ${allSelected ? 'selected' : ''}`}
          >
            {allSelected ? 'Deselect All' : 'Select All'}
          </button>
        </div>
        <div className="category-grid">
          {categories.map(subCategory => (
            <div
              key={subCategory}
              onClick={() => handleSubCategoryToggle(subCategory)}
              className={`category-item ${selectedSubCategories.includes(subCategory) ? 'selected' : ''}`}
            >
              <div className="category-content">
                {getIconForSubCategory(subCategory) && (
                  <img 
                    src={getIconForSubCategory(subCategory)} 
                    alt={subCategory}
                    className="category-icon" 
                  />
                )}
                <span className="category-label">{subCategory}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mobile-view-content">
      <div className="mobile-view-header">
        <h2>Select Categories</h2>
      </div>
      
      <div className="mobile-categories-container">
        {renderCategorySection('News', subCategories.news)}
        {renderCategorySection('Conflict', subCategories.conflict)}
      </div>

      <div className="mobile-view-footer">
        <button onClick={handleSubmit} className="apply-filters-button">
          Apply Filters
        </button>
      </div>
    </div>
  );
};

// Modified subCategories object structure
const subCategories = {
  news: [
    "business",
    "elections",
    "entertainment",
    "finance",
    "financial institution",
    "general",
    "health",
    "legal",
    "science",
    "sports",
    "technology",
    "emergency",
    "accident",
    "flight",
    "vessel"
  ],
  conflict: [
    "air defence",
    "accident/death",
    "airforce",
    "military",
    "missile",
    "navy",
    "navy ship",
    "terror",
    "warzone",
    "explosion"
  ]
};

const categoryIcons = [
  { id: 'accident', label: 'Accident', icon: '/PNG/PNG/accident.png' },
  { id: 'accident/death', label: 'Accident/Death', icon: '/PNG/PNG/accident_death.png' },
  { id: 'air defence', label: 'Air Defence', icon: '/PNG/PNG/air defence.png' },
  { id: 'airforce', label: 'Air Force', icon: '/PNG/PNG/air force.png' },
  { id: 'business', label: 'Business', icon: '/PNG/PNG/business.png' },
  { id: 'elections', label: 'Elections', icon: '/PNG/PNG/elections.png' },
  { id: 'emergency', label: 'Emergency', icon: '/PNG/PNG/emergency.png'},
  { id: 'entertainment', label: 'Entertainment', icon: '/PNG/PNG/entertainment.png' },
  { id: 'explosion', label: 'Explosion', icon: '/PNG/PNG/explosion.png' },
  { id: 'finance', label: 'Finance', icon: '/PNG/PNG/finance.png' },
  { id: 'financial institution', label: 'Financial Institution', icon: '/PNG/PNG/financial institution.png' },
  { id: 'flight', label: 'Flight', icon: '/PNG/PNG/flight.png' },
  { id: 'general', label: 'General Announcement', icon: '/PNG/PNG/General_ Announcement .png' },
  { id: 'health', label: 'Health', icon: '/PNG/PNG/health.png' },
  { id: 'legal', label: 'Legal', icon: '/PNG/PNG/legal.png' },
  { id: 'military', label: 'Military', icon: '/PNG/PNG/military.png' },
  { id: 'missile', label: 'Missile', icon: '/PNG/PNG/missile.png' },
  { id: 'navy', label: 'Navy', icon: '/PNG/PNG/navy.png' },
  { id: 'navy ship', label: 'Navy Ship', icon: '/PNG/PNG/navyship.png' },
  { id: 'science', label: 'Science', icon: '/PNG/PNG/science.png' },
  { id: 'sports', label: 'Sports', icon: '/PNG/PNG/sports.png' },
  { id: 'technology', label: 'Technology', icon: '/PNG/PNG/technology.png' },
  { id: 'terror', label: 'Terror', icon: '/PNG/PNG/terror.png' },
  { id: 'vessel', label: 'Vessel', icon: '/PNG/PNG/vessel.png' },
  { id: 'warzone', label: 'Warzone', icon: '/PNG/PNG/warzone.png' },
];

export default CategoryView;