import React from 'react';
import { AlertTriangle } from 'lucide-react';

const ErrorContainer = ({ message }) => {
  return (
    <div 
      className="error-container" 
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        padding: '20px',
        textAlign: 'center'
      }}
    >
      <div style={{
        maxWidth: '500px',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
      }}>
        <AlertTriangle 
          color="#721c24" 
          size={48} 
          style={{ marginBottom: '15px' }} 
        />
        <h2 style={{ marginBottom: '10px', color: '#721c24' }}>
          An Error Occurred
        </h2>
        <p style={{ color: '#721c24' }}>
          {message || 'An unexpected error has occurred. Please try again later.'}
        </p>
        <button 
          onClick={() => window.location.reload()}
          style={{
            marginTop: '15px',
            padding: '10px 20px',
            backgroundColor: '#721c24',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default ErrorContainer;